import React from 'react';
import { formatDateTime } from '../../utils/dateTimeUtils';
import styles from './CRMList.module.css';

const CRMList = ({ leads = [], selectedColumns = [] }) => {
  const columns = {
    'Click ID': lead => lead.clickId,
    'Campaign Name': lead => lead.campaignName,
    'Country Name': lead => lead.countryName,
    'Signup Date': lead => formatDateTime(lead.signupDate),
    'Sale Status': lead => lead.saleStatus,
    'Network': lead => lead.network || lead.networkname, // Assuming the leads data includes a network field
    'First Name': lead => lead.firstName || lead.firstname,
    'Last Name': lead => lead.lastName || lead.lastname,
    'Email': lead => lead.email,
    'Phone': lead => lead.phone,
    'Offer Name': lead => lead.offerName,
    'Offer Website': lead => lead.offerWebsite,
    'IP Address': lead => lead.ip || lead.userip,
    'Date & Time': lead => formatDateTime(lead.timestamp),
    'Password': lead => lead.password,
    'Call Status': lead => lead.callStatus,
    'Is Smart Click': lead => lead.isSmartClick ? 'Yes' : 'No',
    'MPC_1': lead => lead.tracking?.MPC_1,
    'MPC_2': lead => lead.tracking?.MPC_2,
    'MPC_3': lead => lead.tracking?.MPC_3,
    'MPC_4': lead => lead.tracking?.MPC_4,
    'MPC_5': lead => lead.tracking?.MPC_5,
    'Aff Sub1': lead => lead.tracking?.aff_sub1,
    'Aff Sub2': lead => lead.tracking?.aff_sub2,
    'Aff Sub3': lead => lead.tracking?.aff_sub3,
    'SO': lead => lead.tracking?.so,
    'Sub': lead => lead.tracking?.sub,
    'CO': lead => lead.tracking?.co,
    'Country': lead => lead.tracking?.country,
    'Tracking IP': lead => lead.tracking?.ip,
    'Location': lead => lead.tracking?.location,
    'Lead': lead => <button>{lead.lead}</button>,
    'API Name': lead => lead.apiName, // Added apiName field
    'Name': lead => lead.name || lead.networkname// Added name field
  };

  return (
    <div className={styles.crmList}>
      <table>
        <thead>
          <tr>
            {selectedColumns.map(column => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead._id}>
              {selectedColumns.map(column => (
                <td key={column}>{columns[column] ? columns[column](lead) : 'N/A'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CRMList;
