import React, { useState, useEffect } from 'react';
import Sidebar from '../../dashboard/Sidebar';
import TopMetrics from './TopMetrics';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import styles from './ProxySettings.ps.module.css';
import axios from '../../utils/axiosConfig';

function ProxySettings() {
  const [proxyTemplates, setProxyTemplates] = useState([]);
  const [selectedProxyTemplate, setSelectedProxyTemplate] = useState('');
  const [proxyUsername, setProxyUsername] = useState('');
  const [proxyPassword, setProxyPassword] = useState('');
  const [startingPortGap, setStartingPortGap] = useState(0);
  const [result, setResult] = useState('');

  useEffect(() => {
    const fetchProxyTemplates = async () => {
      try {
        const response = await axios.get('/api/proxy');
        setProxyTemplates(response.data);
      } catch (error) {
        console.error('Error fetching proxy templates:', error);
      }
    };
    fetchProxyTemplates();
  }, []);

  const handleProxyTemplateChange = async (templateId) => {
    setSelectedProxyTemplate(templateId);

    if (templateId) {
      try {
        const response = await axios.get(`/api/proxy/${templateId}`);
        const { userSettings } = response.data;

        if (userSettings) {
          setProxyUsername(userSettings.username);
          setProxyPassword(userSettings.password);
          setStartingPortGap(userSettings.startingPortGap);
        } else {
          setProxyUsername('');
          setProxyPassword('');
          setStartingPortGap(0);
        }
      } catch (error) {
        console.error('Error fetching user proxy settings:', error);
      }
    }
  };

  const handleSaveProxyTemplate = async () => {
    try {
      await axios.post('/api/proxy/save-settings', {
        templateId: selectedProxyTemplate,
        username: proxyUsername,
        password: proxyPassword,
        startingPortGap,
      });
      setResult('Proxy settings saved successfully!');
    } catch (error) {
      console.error('Error saving proxy settings:', error);
      setResult('Error saving proxy settings');
    }
  };

  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="top-metrics">
        <TopMetrics />
      </div>
      <div id="main-content">
        <div className={styles.proxySettings}>
          <h2>Proxy Settings</h2>
          <div className={styles.twoColumn}>
            <div>
              <label>Proxy Template:</label>
              <select
                value={selectedProxyTemplate}
                onChange={(e) => handleProxyTemplateChange(e.target.value)}
              >
                <option value="">Select Proxy Template</option>
                {proxyTemplates.map((template) => (
                  <option key={template._id} value={template._id}>
                    {template.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Username:</label>
              <input
                type="text"
                value={proxyUsername}
                onChange={(e) => setProxyUsername(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.twoColumn}>
            <div>
              <label>Password:</label>
              <input
                type="text"
                value={proxyPassword}
                onChange={(e) => setProxyPassword(e.target.value)}
              />
            </div>
            <div>
              <label>Starting Port Gap:</label>
              <input
                type="number"
                placeholder="Enter gap (e.g., 50)"
                value={startingPortGap}
                onChange={(e) => setStartingPortGap(parseInt(e.target.value, 10))}
              />
            </div>
          </div>
          <button className={styles.saveButton} onClick={handleSaveProxyTemplate}>Save Proxy</button>
          {result && <p>{result}</p>}
        </div>
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default ProxySettings;
