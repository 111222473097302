import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; // Use the shared Axios instance
import TemplateSelector from './TemplateSelector';
import FormFields from './FormFields';
import styles from './Card2.Networks.module.css';

// Update templates array with additional templates
const templates = [
  {
    name: 'SplashAds',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5']
  },
  {
    name: 'BubbleAds',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5']
  },
  {
    name: 'Media',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5']
  },
  {
    name: 'RoiCollective',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5']
  },
  {
    name: 'ffiliates',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5']
  },
  {
    name: 'TrAds',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5']
  },
  {
    name: 'ffiliates',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'pullApiKey', 'username', 'password', 'so', 'ai', 'ci', 'gi']
  },
  {
    name: 'Algoleads',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'pullApiKey', 'username', 'password', 'so', 'ai', 'ci', 'gi']
  },
  {
    name: 'aveAds',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'pullApiKey', 'username', 'password', 'so', 'ai', 'ci', 'gi']
  },
  {
    name: 'RoiBoomers',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'pullApiKey', 'username', 'password', 'so', 'ai', 'ci', 'gi']
  },
  {
    name: 'ixWorld',
    fields: ['apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 'pullApiKey', 'username', 'password', 'so', 'ai', 'ci', 'gi']
  },
  {
    name: 'NewTemplate1',
    fields: ['newField1', 'newField2', 'newField3']
  },
  {
    name: 'NewTemplate2',
    fields: ['anotherField1', 'anotherField2', 'anotherField3']
  }
];

function Card2({ network, onCreateNetwork, onUpdateNetwork, onDeleteNetwork }) {
  const [formData, setFormData] = useState({
    name: '',
    template: '',
    apiKey: '',
    offerName: '',
    offerWebsite: '',
    domainName: '',
    EndPointURL: '',
    APIName: '',
    customFields: {
      custom1: '',
      custom2: '',
      custom3: '',
      custom4: '',
      custom5: ''
    },
    username: '',
    password: '',
    so: '',
    ai: '',
    ci: '',
    gi: '',
    pullApiKey: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [message, setMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    if (network) {
      setFormData({
        ...network,
        customFields: network.customFields || {
          custom1: '',
          custom2: '',
          custom3: '',
          custom4: '',
          custom5: ''
        }
      });
      setIsEditing(false);
      const template = templates.find(template => template.name === network.template);
      setSelectedTemplate(template);
    } else {
      resetForm();
    }
  }, [network]);

  const resetForm = () => {
    setFormData({
      name: '',
      template: '',
      apiKey: '',
      offerName: '',
      offerWebsite: '',
      domainName: '',
      EndPointURL: '',
      APIName: '',
      customFields: {
        custom1: '',
        custom2: '',
        custom3: '',
        custom4: '',
        custom5: ''
      },
      username: '',
      password: '',
      so: '',
      ai: '',
      ci: '',
      gi: '',
      pullApiKey: ''
    });
    setSelectedTemplate(null);
    setIsEditing(true);
  };

  const handleTemplateChange = (templateName) => {
    const selected = templates.find(template => template.name === templateName);
    setSelectedTemplate(selected);
    setFormData(prevFormData => ({
      ...prevFormData,
      template: templateName,
      ...selected.fields.reduce((acc, field) => {
        if (field.startsWith('customFields')) {
          return { ...acc, customFields: { ...acc.customFields, [field.split('.')[1]]: '' } };
        }
        return { ...acc, [field]: '' };
      }, { customFields: {} })
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('customFields')) {
      setFormData(prevFormData => ({
        ...prevFormData,
        customFields: {
          ...prevFormData.customFields,
          [name.split('.')[1]]: value
        }
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (network && network._id) {
      await handleSave();
    } else {
      await handleCreate();
    }
  };

  const handleCreate = async () => {
    try {
      const response = await axios.post('/api/networks/create', formData);
      const newNetwork = response.data;

      // Trigger download of created network details
      const blobResponse = await axios.get(`/api/networks/${newNetwork._id}/download`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([blobResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${newNetwork.APIName}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      onCreateNetwork(newNetwork);
      setMessage({ text: `${formData.name} successfully created`, type: 'success' });
      setTimeout(() => setMessage({ text: '', type: '' }), 4000); // Hide message after 4 seconds
      resetForm();
    } catch (error) {
      setMessage({ text: `Error creating network: ${error.message}`, type: 'error' });
      setTimeout(() => setMessage({ text: '', type: '' }), 4000); // Hide message after 4 seconds
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(`/api/networks/${network._id}`, formData);
      const updatedNetwork = response.data;

      // Trigger download of updated network details
      const blobResponse = await axios.get(`/api/networks/${updatedNetwork._id}/download`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([blobResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${updatedNetwork.APIName}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      onUpdateNetwork(updatedNetwork);
      setIsEditing(false);
      setMessage({ text: `${formData.name} successfully saved`, type: 'success' });
      setTimeout(() => setMessage({ text: '', type: '' }), 4000); // Hide message after 4 seconds
    } catch (error) {
      setMessage({ text: `Error saving network: ${error.message}`, type: 'error' });
      setTimeout(() => setMessage({ text: '', type: '' }), 4000); // Hide message after 4 seconds
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/networks/${network._id}`);
      onDeleteNetwork(network._id);
      setMessage({ text: `${formData.name} successfully deleted`, type: 'success' });
      setTimeout(() => setMessage({ text: '', type: '' }), 4000); // Hide message after 4 seconds
      resetForm();
    } catch (error) {
      setMessage({ text: `Error deleting network: ${error.message}`, type: 'error' });
      setTimeout(() => setMessage({ text: '', type: '' }), 4000); // Hide message after 4 seconds
    }
  };

  const toggleEditing = () => {
    setIsEditing(true);
  };

  return (
    <div className={styles.card2}>
      {message.text && (
        <div className={`${styles.message} ${styles[message.type]}`}>
          {message.text}
        </div>
      )}
      <h2>{network && network._id ? (isEditing ? 'Edit Network' : 'Network Details') : 'Create Network'}</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Network Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              disabled={!isEditing}
            />
          </div>
          <div className={styles.formGroup}>
            <TemplateSelector
              template={formData.template}
              handleTemplateChange={handleTemplateChange}
              isEditing={isEditing}
              network={network}
              templates={templates}
            />
          </div>
        </div>
        <FormFields
          selectedTemplate={selectedTemplate}
          formData={formData}
          handleChange={handleChange}
          isEditing={isEditing}
        />
        <div className={styles.formActions}>
          {network && network._id && !isEditing ? (
            <button type="button" className={styles.createButton} onClick={toggleEditing}>Edit</button>
          ) : (
            <>
              {network && network._id && (
                <button type="button" className={styles.saveButton} onClick={handleSubmit}>Save</button>
              )}
              {!network && (
                <button type="submit" className={styles.createButton}>Create</button>
              )}
            </>
          )}
          {network && network._id && (
            <button type="button" className={styles.deleteButton} onClick={handleDelete}>Delete</button>
          )}
        </div>
      </form>
    </div>
  );
}

export default Card2;
