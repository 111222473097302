import React, { useEffect, useState } from 'react';
import './RightSidebar.css';

const RightSidebar = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    // Simulate receiving real-time updates
    const interval = setInterval(() => {
      setActivities(prevActivities => [
        ...prevActivities,
        `New activity at ${new Date().toLocaleTimeString()}`
      ]);
    }, 500000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="right-sidebar">
      <h3>Network Activity Stream</h3>
      <div className="activity-card">
        {activities.map((activity, index) => (
          <div key={index} className="activity">
            {activity}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RightSidebar;
