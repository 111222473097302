import axios from 'axios';

// Set base URL for your API using environment variable
axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:5001'; // Fallback to localhost if env variable is not set

// Set token in headers if it exists
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['x-auth-token'] = token;
}

// Interceptor to handle token expiry
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Token expired or unauthorized, prompt user to log in again
      alert('Your session has expired. Please log in again.');
      localStorage.removeItem('token'); // Clear the token
      window.location.href = '/login'; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default axios;
