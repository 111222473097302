import React from 'react';
import { formatDateTime } from '../../utils/dateTimeUtils';
import styles from './LeadsList.module.css';

const LeadsList = ({ leads = [], selectedColumns = [] }) => {
  const columns = {
    'Click ID': lead => lead.clickId,
    'Campaign Name': lead => lead.leadData.campaignName,
    'Country Name': lead => lead.leadData.countryName,
    'Signup Date': lead => formatDateTime(lead.signupDate),
    'Sale Status': lead => lead.leadData.saleStatus || lead.leadData?.customerData?.call_status,
    'Network': lead => lead.network, // Assuming the leads data includes a network field
    'Has FTD': lead => lead.leadData.hasFTD,
    'Country Code': lead => lead.leadData.countryCode,
    'Email': lead => lead.leadData.email,
    'Custom5': lead => lead.leadData.custom5,
  };

  return (
    <div className={styles.leadsList}>
      <table>
        <thead>
          <tr>
            {selectedColumns.map(column => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead._id}>
              {selectedColumns.map(column => (
                <td key={column}>{columns[column] ? columns[column](lead) : 'N/A'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeadsList;
