import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from '../utils/axiosConfig';
import styles from './Card1.Schedule.module.css';

function Card1({ onDelete, onUpload }) {
  const [selectedSection, setSelectedSection] = useState(null);
  const [deleteRange, setDeleteRange] = useState('');
  const [proxyTemplates, setProxyTemplates] = useState([]);
  const [selectedProxyTemplate, setSelectedProxyTemplate] = useState('');
  const [proxyUsername, setProxyUsername] = useState('');
  const [proxyPassword, setProxyPassword] = useState('');
  const [startingPortGap, setStartingPortGap] = useState(0);
  const [userAgentOption, setUserAgentOption] = useState('system');
  const [formData, setFormData] = useState({
    offerLink: '',
    countryCode: '',
    rowRange: '',
  });
  const [offers, setOffers] = useState([]);
  const [trackboxOffers, setTrackboxOffers] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryRowRanges, setCountryRowRanges] = useState({});
  const [selectedOffer, setSelectedOffer] = useState('');
  const [selectedOfferLink, setSelectedOfferLink] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [rowRange, setRowRange] = useState({ from: '', to: '' });
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [result, setResult] = useState('');
  const [scheduleTime, setScheduleTime] = useState({ date: '', time: '' });
  const [feedbackMessage, setFeedbackMessage] = useState(''); // New state for feedback messages
  const [feedbackColor, setFeedbackColor] = useState(''); // New state for feedback color

  useEffect(() => {
    const fetchOffersAndCountryCodes = async () => {
      try {
        const [offersResponse, trackboxOffersResponse, countryCodesResponse] = await Promise.all([
          axios.get('/api/offers'),
          axios.get('/api/trackboxoffers'),
          axios.get('/api/schedule/data'),
        ]);

        if (Array.isArray(offersResponse.data)) {
          setOffers(offersResponse.data);
        } else {
          console.error('Offers response is not an array:', offersResponse.data);
        }

        if (Array.isArray(trackboxOffersResponse.data)) {
          setTrackboxOffers(trackboxOffersResponse.data);
        } else {
          console.error('Trackbox offers response is not an array:', trackboxOffersResponse.data);
        }

        if (Array.isArray(countryCodesResponse.data)) {
          const countryRowRanges = {};

          countryCodesResponse.data.forEach((item, index) => {
            const { CountryCode } = item;

            if (!countryRowRanges[CountryCode]) {
              countryRowRanges[CountryCode] = { from: index + 1, to: index + 1 };
            } else {
              countryRowRanges[CountryCode].to = index + 1;
            }
          });

          setCountryCodes(Object.keys(countryRowRanges));
          setCountryRowRanges(countryRowRanges);
        } else {
          console.error('Country codes response is not an array:', countryCodesResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchOffersAndCountryCodes();
  }, []);

  useEffect(() => {
    const fetchProxyTemplates = async () => {
      try {
        const response = await axios.get('/api/proxy');
        if (Array.isArray(response.data)) {
          setProxyTemplates(response.data);
        } else {
          console.error('Proxy templates response is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching proxy templates:', error);
      }
    };

    fetchProxyTemplates();
  }, []);

  useEffect(() => {
    const fetchScheduledTasks = async () => {
      try {
        const response = await axios.get('/api/schedule/tasks');
        if (Array.isArray(response.data)) {
          setScheduledTasks(response.data);
        } else {
          console.error('Scheduled tasks response is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching scheduled tasks:', error);
      }
    };
    fetchScheduledTasks();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDelete = useCallback(() => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/schedule/delete/${deleteRange}`)
      .then((response) => {
        onDelete(response.data.data);
        setDeleteRange('');
        setFeedbackMessage('Rows deleted successfully!');
        setFeedbackColor('green');
        setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
        window.location.reload(); // Refresh Card1 after deletion
      })
      .catch((error) => {
        setFeedbackMessage(`Error deleting rows: ${error.response.data.message}`);
        setFeedbackColor('red');
        setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
      });
  }, [deleteRange, onDelete]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(`${process.env.REACT_APP_API_URL}/api/schedule/upload`, formData)
        .then((response) => {
          onUpload(response.data.data);
          setFeedbackMessage('File uploaded successfully!');
          setFeedbackColor('green');
          setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
        })
        .catch((error) => {
          setFeedbackMessage(`Error uploading file: ${error.response.data.message}`);
          setFeedbackColor('red');
          setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
        });
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: '.xlsx, .xls',
  });

  const handleSchedule = async () => {
    try {
      const response = await axios.post('/api/schedule/run-task', {
        userAgent: userAgentOption,
        formData: {
          ...formData,
          offerLink: selectedOfferLink,
          countryCode: selectedCountryCode,
          rowRange: `${rowRange.from}-${rowRange.to}`,
        },
        startTime: `${scheduleTime.date}T${scheduleTime.time}:00.000Z`,
      });
      setResult(JSON.stringify(response.data, null, 2));
      setFeedbackMessage('Task scheduled successfully!');
      setFeedbackColor('green');
      setTimeout(() => {
        setFeedbackMessage('');
        setSelectedSection(null); // Reset tab colors after success
      }, 3000);
      const tasksResponse = await axios.get('/api/schedule/tasks');
      setScheduledTasks(tasksResponse.data);
    } catch (error) {
      setFeedbackMessage(`Error scheduling task: ${error.response.data.message}`);
      setFeedbackColor('red');
      setTimeout(() => setFeedbackMessage(''), 3000);
    }
  };

  const assignRows = () => {
    setFormData((prevData) => ({
      ...prevData,
      offerLink: selectedOfferLink,
      countryCode: selectedCountryCode,
      rowRange: `${rowRange.from}-${rowRange.to}`,
    }));
    setFeedbackMessage('Rows assigned successfully!');
    setFeedbackColor('green');
    setTimeout(() => setFeedbackMessage(''), 3000); // Clear message after 3 seconds
  };

  const handleOfferChange = (e) => {
    const selectedOffer = offers.find(offer => offer.offerLink === e.target.value) ||
                          trackboxOffers.find(offer => offer.offerLink === e.target.value);
    setSelectedOffer(e.target.value);
    setSelectedOfferLink(selectedOffer ? selectedOffer.offerLink : '');
  };

  const handleCountryCodeChange = (e) => {
    const selectedCountryCode = e.target.value;
    setSelectedCountryCode(selectedCountryCode);

    if (selectedCountryCode) {
      const { from, to } = countryRowRanges[selectedCountryCode];
      setRowRange({ from, to });
    } else {
      setRowRange({ from: '', to: '' });
    }
  };

  return (
    <div className={styles.card1}>
      {feedbackMessage && (
        <div className={styles.feedback} style={{ color: feedbackColor }}>
          {feedbackMessage}
        </div>
      )}
      <div className={styles.sectionButtons}>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'uploadFile' ? styles.active : ''}`}
          onClick={() => setSelectedSection('uploadFile')}
        >
          Upload File
        </button>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'offerCountry' ? styles.active : ''}`}
          onClick={() => setSelectedSection('offerCountry')}
        >
          Select Offer and Country
        </button>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'assignedRows' ? styles.active : ''}`}
          onClick={() => setSelectedSection('assignedRows')}
        >
          Assigned Rows Summary
        </button>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'scheduleTime' ? styles.active : ''}`}
          onClick={() => setSelectedSection('scheduleTime')}
        >
          Choose Schedule Time
        </button>
        <button
          className={`${styles.sectionButton} ${selectedSection === 'deleteRows' ? styles.active : ''}`}
          onClick={() => setSelectedSection('deleteRows')}
        >
          Delete Rows
        </button>
      </div>
      <div className={styles.sectionContent}>
        {selectedSection === 'uploadFile' && (
          <div className={styles.uploadSection}>
            <div {...getRootProps({ className: styles.dropzone })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop an Excel file here, or click to select one</p>
            </div>
          </div>
        )}

        {selectedSection === 'offerCountry' && (
          <div className={styles.offerCountrySection}>
            <div className={styles.twoColumn}>
              <div>
                <label>Offer:</label>
                <select
                  value={selectedOffer}
                  onChange={handleOfferChange}
                >
                  <option value="">Select Offer</option>
                  {offers.map((offer) => (
                    <option key={offer._id} value={offer.offerLink}>
                      {offer.offerName}
                    </option>
                  ))}
                  {trackboxOffers.map((offer) => (
                    <option key={offer._id} value={offer.offerLink}>
                      {offer.offerName}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>Offer Link:</label>
                <input
                  type="text"
                  value={selectedOfferLink}
                  onChange={(e) => setSelectedOfferLink(e.target.value)}
                  placeholder="Enter or select offer link"
                />
              </div>
            </div>
            <div className={styles.twoColumn}>
              <div>
                <label>Country Code:</label>
                <select
                  value={selectedCountryCode}
                  onChange={handleCountryCodeChange}
                >
                  <option value="">Select Country Code</option>
                  {countryCodes.map((code, index) => (
                    <option key={index} value={code}>
                      {code}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.rowRange}>
                <label>Row Range:</label>
                <div className={styles.rangeInputs}>
                  <select
                    value={rowRange.from}
                    onChange={(e) => setRowRange({ ...rowRange, from: e.target.value })}
                  >
                    {Array.from({ length: countryRowRanges[selectedCountryCode]?.to - countryRowRanges[selectedCountryCode]?.from + 1 || 0 }, (_, i) => (
                      <option key={i} value={countryRowRanges[selectedCountryCode]?.from + i}>
                        {countryRowRanges[selectedCountryCode]?.from + i}
                      </option>
                    ))}
                  </select>
                  <select
                    value={rowRange.to}
                    onChange={(e) => setRowRange({ ...rowRange, to: e.target.value })}
                  >
                    {Array.from({ length: countryRowRanges[selectedCountryCode]?.to - countryRowRanges[selectedCountryCode]?.from + 1 || 0 }, (_, i) => (
                      <option key={i} value={countryRowRanges[selectedCountryCode]?.from + i}>
                        {countryRowRanges[selectedCountryCode]?.from + i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <button className={styles.assignButton} onClick={assignRows}>Assign Rows</button>
          </div>
        )}

        {selectedSection === 'assignedRows' && (
          <div className={styles.assignedRowsSection}>
            <ul>
              <li>Offer Link: {formData.offerLink}</li>
              <li>Country Code: {formData.countryCode}</li>
              <li>Row Range: {formData.rowRange}</li>
            </ul>
          </div>
        )}

        {selectedSection === 'scheduleTime' && (
          <div className={styles.scheduleTimeSection}>
            <div className={styles.twoColumn}>
              <div>
                <label>Date:</label>
                <input
                  type="date"
                  value={scheduleTime.date}
                  onChange={(e) => setScheduleTime({ ...scheduleTime, date: e.target.value })}
                />
              </div>
              <div>
                <label>Time:</label>
                <input
                  type="time"
                  value={scheduleTime.time}
                  onChange={(e) => setScheduleTime({ ...scheduleTime, time: e.target.value })}
                />
              </div>
            </div>
            <button className={styles.actionButton} onClick={handleSchedule}>Schedule</button>
          </div>
        )}

        {selectedSection === 'deleteRows' && (
          <div className={styles.deleteRowsSection}>
            <label>Enter Row Range to Delete:</label>
            <input
              type="text"
              value={deleteRange}
              onChange={(e) => setDeleteRange(e.target.value)}
              placeholder="Enter row range (e.g., 10-15)"
            />
            <button className={styles.deleteButton} onClick={handleDelete}>Delete Rows</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Card1;
