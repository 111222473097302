import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import styles from './Card1.Offers.module.css';

function Card1Offers({ onSelectOffer, offers, trackboxOffers, setOffers, setTrackboxOffers }) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const [regularOffersResponse, trackboxOffersResponse] = await Promise.all([
          axios.get('api/offers'),
          axios.get('api/trackboxoffers')
        ]);
        setOffers(regularOffersResponse.data);
        setTrackboxOffers(trackboxOffersResponse.data);
      } catch (error) {
        console.error('Error fetching offers', error);
      }
    };
    fetchOffers();
  }, [setOffers, setTrackboxOffers]);

  const filteredOffers = offers.filter(offer => 
    offer.offerName && offer.offerName.toLowerCase().includes(search.toLowerCase())
  );

  const filteredTrackboxOffers = trackboxOffers.filter(offer => 
    offer.offerName && offer.offerName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={styles.card1}>
      <div>
        <input
          type="text"
          placeholder="Search Offers"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchBox}
        />
      </div>
      <div className={styles.cardOffers}>
        {filteredOffers.length === 0 && filteredTrackboxOffers.length === 0 ? (
          <div>No offers found</div>
        ) : (
          <>
            {filteredOffers.map(offer => (
              <div key={offer._id} className={styles.offerItem}>
                <button
                  className={styles.offerButton}
                  onClick={() => onSelectOffer(offer)}
                >
                  {offer.offerName || 'Unnamed Offer'}
                </button>
              </div>
            ))}
            {filteredTrackboxOffers.map(offer => (
              <div key={offer._id} className={styles.offerItem}>
                <button
                  className={styles.offerButton}
                  onClick={() => onSelectOffer(offer)}
                >
                  {offer.offerName || 'Unnamed Trackbox Offer'}
                </button>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Card1Offers;
