import React, { useState } from 'react';
import Sidebar from './Sidebar';
import TopMetrics from './TopMetrics';
import MainContent from './MainContent';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import './Dashboard.css';

function Dashboard() {
  const [dateRange, setDateRange] = useState('today');

  const handleApplyFilters = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return (
    <div className="dashboard-grid">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="top-metrics">
        <TopMetrics onApplyFilters={handleApplyFilters} />
      </div>
      <div id="main-content">
        <MainContent dateRange={dateRange} />
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default Dashboard;
