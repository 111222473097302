import React from 'react';
import styles from './Card2.Offers.module.css';

// Function to generate a unique clickid
const generateUniqueClickId = () => {
  return 'clickid_' + Math.random().toString(36).substr(2, 9);
};

function Card2Offers({ offer }) {
  if (!offer) {
    return <div className={styles.card2}>Select an offer to view details</div>;
  }

  const handleCopyLink = () => {
    const uniqueClickId = generateUniqueClickId(); // Generate a unique clickid
    const offerLinkWithClickId = offer.offerLink.replace('{clickid}', uniqueClickId);

    navigator.clipboard.writeText(offerLinkWithClickId).then(() => {
      alert('Offer link copied to clipboard');
    });
  };

  return (
    <div className={styles.card2}>
      <h2>{offer.offerName}</h2>
      <p>Network: {offer.network.name}</p>
      <p>{offer.offerDetails}</p>
      <p>Offer Link: {offer.offerLink.replace('{clickid}', '<clickid>')}</p>
      <button onClick={handleCopyLink} className={styles.copyButton}>Copy Offer Link</button>
    </div>
  );
}

export default Card2Offers;
