import React, { useState } from 'react';
import axios from '../utils/axiosConfig'; // Import the configured axios instance
import { FaUser, FaLock, FaEnvelope, FaPhone } from 'react-icons/fa';
import './Register.css';

function Register() {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    password: ''
  });

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/register', formData); // Updated path to match backend route
      const { token } = response.data;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['x-auth-token'] = token; // Set token in headers after registration
      setMessage('Registration successful! Redirecting to login...');
      setIsError(false);
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000); // Redirect after 3 seconds
    } catch (error) {
      setMessage(error.response.data.msg || 'Registration failed');
      setIsError(true);
    }
  };

  return (
    <div className="page-container">
      <div className="card-container card-container-1"></div>
      <div className="card-container card-container-2"></div>
      <div className="register-container">
        <form onSubmit={handleRegister} className="register-form">
          <div className="register-logo">
            <img src="/logo.png" alt="Logo" />
          </div>
          <h2>Create an account</h2>
          {message && (
            <div className={`message ${isError ? 'error' : 'success'}`}>
              {message}
            </div>
          )}
          <div className="input-group">
            <FaEnvelope />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
          <div className="input-group">
            <FaUser />
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
            />
          </div>
          <div className="input-group">
            <FaUser />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
            />
          </div>
          <div className="input-group">
            <FaPhone />
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
              required
            />
          </div>
          <div className="input-group">
            <FaLock />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
          </div>
          <button type="submit">Register</button>
          <div className="register-footer">
            <p>
              Already have an account? <a href="/login">Login</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
