import React from 'react';
import Sidebar from '../dashboard/Sidebar';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import FTDSComponent from '../Tracking/FTDS/FTDSComponent'; // Correct casing for FTDSComponent
import styles from './FTDS.FTDS.module.css';

function FTDS() {
  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="main-content">
        <FTDSComponent /> {/* Use FTDSComponent here */}
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default FTDS;
