import React, { useState, useEffect } from 'react';
import Chart from '../dashboard/Chart';
import { fetchLeadCount, fetchFTDCount } from '../utils/Trackingapi';
import './MainContent.css';

function MainContent({ dateRange }) {
  const [leadCount, setLeadCount] = useState(0);
  const [ftdCount, setFtdCount] = useState(0);
  const [data, setData] = useState({
    labels: Array.from({ length: 30 }, (_, i) => i + 1),
    leads: Array(30).fill(0),
    ftd: Array(30).fill(0),
    revenue: Array(30).fill(0),
  });

  useEffect(() => {
    const updateCounts = async () => {
      try {
        const leadResponse = await fetchLeadCount(dateRange);
        const ftdResponse = await fetchFTDCount(dateRange);
        setLeadCount(leadResponse.count);
        setFtdCount(ftdResponse.count);
      } catch (error) {
        console.error('Error updating counts:', error);
      }
    };

    updateCounts();
  }, [dateRange]);

  return (
    <div className="main-content">
      <div className="card schedule-card">
        <h3>Schedule</h3>
        <div className="schedule-status">
          <div className="status-box">
            <span>In Progress</span>
            <span className="status-value">0</span>
          </div>
          <div className="status-box">
            <span>Completed</span>
            <span className="status-value">0</span>
          </div>
          <div className="status-box">
            <span>Pending</span>
            <span className="status-value">0</span>
          </div>
        </div>
      </div>
      <div className="card leads-card">
        <h3>Leads</h3>
        <span className="card-value">{leadCount}</span>
      </div>
      <div className="card ftd-card">
        <h3>FTD</h3>
        <span className="card-value">{ftdCount}</span>
      </div>
      <div className="card revenue-card">
        <h3>Revenue</h3>
        <span className="card-value">$0</span>
      </div>
      <div className="card chart-card">
        <h3>Chart</h3>
        <div className="chart-container">
          <Chart data={data} />
        </div>
      </div>
    </div>
  );
}

export default MainContent;
