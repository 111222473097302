import React, { useState, useEffect } from 'react';
import { getCurrentDateTime } from '../utils/dateTimeUtils'; // Import the utility function
import styles from './TopMetrics.FTDS.module.css'; // Updated to use FTDs-specific CSS

function TopMetrics({ dateRange, setDateRange, ftdType, setFtdType, selectedColumns, setSelectedColumns, onApplyFilters }) {
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 1000); // Update the time every second

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  const handleColumnSelection = (column) => {
    setSelectedColumns((prev) =>
      prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
    );
  };

  const handleApply = () => {
    onApplyFilters(dateRange, ftdType, selectedColumns); // Pass filters to parent
  };

  return (
    <div className={styles.topMetrics}>
      <div className={styles.header}>
        <h2>FTDs</h2> {/* Updated the heading to FTDs */}
        <div className={styles.dateTime}>{currentDateTime}</div> {/* Display the current date and time */}
      </div>
      <div className={styles.buttonCard}>
        <button className={`${styles.filterButton} ${dateRange === 'today' ? styles.active : ''}`} onClick={() => setDateRange('today')}>Today</button>
        <button className={`${styles.filterButton} ${dateRange === 'yesterday' ? styles.active : ''}`} onClick={() => setDateRange('yesterday')}>Yesterday</button>
        <button className={`${styles.filterButton} ${dateRange === 'thisWeek' ? styles.active : ''}`} onClick={() => setDateRange('thisWeek')}>This Week</button>
        <button className={`${styles.filterButton} ${dateRange === 'lastWeek' ? styles.active : ''}`} onClick={() => setDateRange('lastWeek')}>Last Week</button>
        <button className={`${styles.filterButton} ${dateRange === 'thisMonth' ? styles.active : ''}`} onClick={() => setDateRange('thisMonth')}>This Month</button>
        <button className={`${styles.filterButton} ${dateRange === 'lastMonth' ? styles.active : ''}`} onClick={() => setDateRange('lastMonth')}>Last Month</button>
        <button className={`${styles.filterButton} ${dateRange === 'custom' ? styles.active : ''}`} onClick={() => setDateRange('custom')}>Custom</button>
      </div>
      <div className={styles.dropdownCard}>
        {/* Additional dropdowns or selections specific to FTDs can be added here */}
      </div>
    </div>
  );
}

export default TopMetrics;
