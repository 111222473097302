import React, { useState } from 'react';
import axios from '../utils/axiosConfig'; // Import the configured axios instance
import { FaUser, FaLock } from 'react-icons/fa';
import ForgotPassword from './ForgotPassword'; // Import the ForgotPassword component
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false); // State to manage the forgot password popup

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', { email, password }); // Updated path to match backend route
      const { token } = response.data;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['x-auth-token'] = token; // Set token in headers after login
      setMessage('Login successful! Redirecting to dashboard...');
      setIsError(false);
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 3000); // Redirect after 3 seconds
    } catch (error) {
      setMessage(error.response.data.msg || 'Login failed');
      setIsError(true);
    }
  };

  return (
    <div className="page-container">
      <div className="card-container card-container-1"></div>
      <div className="card-container card-container-2"></div>
      <div className="login-container">
        <form onSubmit={handleLogin} className="login-form">
          <div className="login-logo">
            <img src="/logo.png" alt="Logo" />
          </div>
          <h2>Sign in to your account</h2>
          {message && (
            <div className={`message ${isError ? 'error' : 'success'}`}>
              {message}
            </div>
          )}
          <div className="input-group">
            <FaUser />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className="input-group">
            <FaLock />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          <button type="submit">Sign In</button>
          <div className="login-footer">
            <button type="button" onClick={() => setShowForgotPassword(true)}>
              Forgot Your Password?
            </button>
            <p>
              Don't have an account? <a href="/register">Register</a>
            </p>
          </div>
        </form>
      </div>
      {showForgotPassword && <ForgotPassword onClose={() => setShowForgotPassword(false)} />}
    </div>
  );
}

export default Login;
