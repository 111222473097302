import React, { useState, useEffect } from 'react';
import Sidebar from '../../dashboard/Sidebar';
import TopMetrics from './TopMetrics';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import styles from './ScheduledTasks.st.module.css';
import axios from '../../utils/axiosConfig';

function ScheduledTasks() {
  const [scheduledTasks, setScheduledTasks] = useState([]);

  useEffect(() => {
    const fetchScheduledTasks = async () => {
      try {
        const response = await axios.get('/api/schedule/tasks');
        setScheduledTasks(response.data);
      } catch (error) {
        console.error('Error fetching scheduled tasks:', error);
      }
    };
    fetchScheduledTasks();
  }, []);

  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="top-metrics">
        <TopMetrics />
      </div>
      <div id="main-content">
        <div className={styles.scheduledTasks}>
          <h2>Scheduled Tasks</h2>
          {scheduledTasks.length > 0 ? (
            <ul className={styles.taskList}>
              {scheduledTasks.map(task => (
                <li key={task.id} className={styles.taskItem}>
                  <button className={styles.taskButton}>{task.name || `Task ${task.id}`}</button>
                </li>
              ))}
            </ul>
          ) : (
            <p className={styles.noTasksMessage}>No scheduled tasks found.</p>
          )}
        </div>
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default ScheduledTasks;
