import React from 'react';
import styles from './RightSidebar.Networks.module.css';

function RightSidebar() {
  return (
    <div className={styles.rightSidebar}>
      <h3></h3>
      <div></div>
      <div></div>
    </div>
  );
}

export default RightSidebar;
