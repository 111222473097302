import React, { useEffect, useState, useCallback } from 'react';
import { fetchFTDSData, fetchTrackboxFTDSData } from '../../utils/Trackingapi';
import TopMetricsFTDS from '../../FTDS/TopMetricsFTDS';
import FTDSList from './FTDSList';

const FTDSComponent = () => {
  const [ftds, setFTDS] = useState([]);
  const [dateRange, setDateRange] = useState('today'); // Default to 'today'
  const [ftdType, setFtdType] = useState(''); // Use state for FTD type
  const [selectedColumns, setSelectedColumns] = useState([
    'Click ID', 'Campaign Name', 'Country Name', 'Signup Date', 'Sale Status', 'Network', 'Has FTD', 'Country Code', 'Email', 'Custom5'
  ]);

  const loadFTDSData = useCallback(async () => {
    try {
      const [ftdsData, trackboxFTDSData] = await Promise.all([
        fetchFTDSData(dateRange, ftdType), 
        fetchTrackboxFTDSData(dateRange, ftdType)
      ]);
      const combinedFTDSData = [...ftdsData, ...trackboxFTDSData];
      setFTDS(combinedFTDSData);
    } catch (error) {
      console.error('Error loading FTDS data:', error);
    }
  }, [dateRange, ftdType]);

  useEffect(() => {
    loadFTDSData();
  }, [loadFTDSData]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadFTDSData();
    }, 30000); // Fetch FTDS data every 30 seconds

    return () => clearInterval(interval);
  }, [loadFTDSData]);

  const handleApplyFilters = (newDateRange, newFtdType, newSelectedColumns) => {
    setDateRange(newDateRange);
    setFtdType(newFtdType);
    setSelectedColumns(newSelectedColumns);
    loadFTDSData(); // Fetch FTDS data based on the new filters
  };

  return (
    <div>
      <TopMetricsFTDS
        dateRange={dateRange}
        setDateRange={setDateRange}
        ftdType={ftdType}
        setFtdType={setFtdType}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        onApplyFilters={handleApplyFilters}
      />
      <h1>FTDS Report</h1>
      <FTDSList ftds={ftds} selectedColumns={selectedColumns} />
    </div>
  );
};

export default FTDSComponent;
