import React, { useState, useEffect } from 'react';
import { getCurrentDateTime } from '../utils/dateTimeUtils';
import styles from './TopMetrics.crm.module.css';

const allColumns = [
  'Lead ID', 'First Name', 'Last Name', 'Email', 'Phone', 'Offer Name', 'Offer Website', 'IP Address', 'Date & Time',
  'Password', 'Sale Status', 'Call Status', 'Is Smart Click',
  'MPC_1', 'MPC_2', 'MPC_3', 'MPC_4', 'MPC_5',
  'Aff Sub1', 'Aff Sub2', 'Aff Sub3', 'SO', 'Sub', 'CO', 'Country', 'Tracking IP', 'Location'
];

function TopMetrics({ dateRange, setDateRange, leadType, setLeadType, selectedColumns, setSelectedColumns, onApplyFilters }) {
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());
  const [showColumnsDropdown, setShowColumnsDropdown] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleColumnSelection = (column) => {
    setSelectedColumns((prev) =>
      prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]
    );
  };

  const handleApply = () => {
    onApplyFilters(dateRange, leadType, selectedColumns); // Pass filters to parent
    setShowColumnsDropdown(false); // Hide the dropdown after applying filters
  };

  return (
    <div className={styles.topMetrics}>
      <div className={styles.header}>
        <h2>CRM</h2>
        <div className={styles.dateTime}>{currentDateTime}</div>
      </div>
      <div className={styles.buttonCard}>
        <button className={`${styles.filterButton} ${dateRange === 'today' ? styles.active : ''}`} onClick={() => setDateRange('today')}>Today</button>
        <button className={`${styles.filterButton} ${dateRange === 'yesterday' ? styles.active : ''}`} onClick={() => setDateRange('yesterday')}>Yesterday</button>
        <button className={`${styles.filterButton} ${dateRange === 'thisWeek' ? styles.active : ''}`} onClick={() => setDateRange('thisWeek')}>This Week</button>
        <button className={`${styles.filterButton} ${dateRange === 'lastWeek' ? styles.active : ''}`} onClick={() => setDateRange('lastWeek')}>Last Week</button>
        <button className={`${styles.filterButton} ${dateRange === 'thisMonth' ? styles.active : ''}`} onClick={() => setDateRange('thisMonth')}>This Month</button>
        <button className={`${styles.filterButton} ${dateRange === 'lastMonth' ? styles.active : ''}`} onClick={() => setDateRange('lastMonth')}>Last Month</button>
        <button className={`${styles.filterButton} ${dateRange === 'custom' ? styles.active : ''}`} onClick={() => setDateRange('custom')}>Custom</button>
      </div>
      <div className={styles.dropdownCard}>
        <div className={styles.dropdown}>
          <button className={`${styles.dropdownToggle} ${styles.customDropdown}`} onClick={() => setShowColumnsDropdown(!showColumnsDropdown)}>
            All Columns
          </button>
          {showColumnsDropdown && (
            <div className={styles.columnsDropdown}>
              {allColumns.map((column) => (
                <label key={column} className={styles.columnOption}>
                  <input
                    type="checkbox"
                    checked={selectedColumns.includes(column)}
                    onChange={() => handleColumnSelection(column)}
                  />
                  {column}
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopMetrics;
