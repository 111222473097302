import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; // Use the shared Axios instance
import styles from './Card1.Networks.module.css';

function Card1({ onCreateNetwork, onSelectNetwork, networks, setNetworks }) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchNetworks = async () => {
      try {
        const response = await axios.get('/api/networks');
        setNetworks(response.data);
      } catch (error) {
        console.error('Error fetching networks', error);
      }
    };
    fetchNetworks();
  }, [setNetworks]);

  const filteredNetworks = networks.filter(network => 
    network.name && network.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={styles.card1}>
      <div className={styles.cardCreate}>
        <button className={styles.createButton} onClick={onCreateNetwork}>Create New Network</button>
      </div>
      <div>
        <input
          type="text"
          placeholder="Search Networks"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchBox}
        />
      </div>
      <div className={styles.cardNetworks} style={{ overflowY: 'scroll', maxHeight: '400px' }}>
        {filteredNetworks.length === 0 ? (
          <div>No networks found</div>
        ) : (
          filteredNetworks.map(network => (
            <div key={network._id} className={styles.networkItem}>
              <button
                className={styles.networkButton}
                onClick={() => onSelectNetwork(network)}
              >
                {network.name || 'Unnamed Network'}
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Card1;
