import React, { useState, useEffect } from 'react';
import { getCurrentDateTime } from '../utils/dateTimeUtils';
import styles from './TopMetrics.Networks.module.css';

function TopMetrics() {
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.topMetrics}>
      <div className={styles.header}>
        <h2>Networks</h2> {/* Updated to reflect the Networks page */}
        <div className={styles.dateTime}>{currentDateTime}</div>
 
      </div>
      <div className={styles.dropdownCard}>
        <select className={styles.dropdown}>
          <option>All Networks</option> {/* Updated to reflect the Networks page */}
        </select>
        <select className={styles.dropdown}>
          <option>Geos</option>
        </select>
        <button className={styles.applyButton}>Apply</button>
      </div>
    </div>
  );
}

export default TopMetrics;
