import React from 'react';
import Sidebar from './Sidebar';
import TopMetrics from './TopMetrics';
import MainContent from './MainContentOffers';
import BottomSection from './BottomSection';
import styles from './Offers.Offers.module.css';

function Offers() {
  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="top-metrics">
        <TopMetrics />
      </div>
      <div id="main-content">
        <MainContent />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default Offers;
