import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; // Use the shared Axios instance
import Card1Offers from './Card1Offers';
import Card2Offers from './Card2Offers';
import styles from './MainContent.Offers.module.css';

function MainContentOffers() {
  const [offers, setOffers] = useState([]);
  const [trackboxOffers, setTrackboxOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const [regularOffersResponse, trackboxOffersResponse] = await Promise.all([
          axios.get('api/offers'),
          axios.get('api/trackboxoffers')
        ]);
        setOffers(regularOffersResponse.data);
        setTrackboxOffers(trackboxOffersResponse.data);
      } catch (error) {
        console.error('Error fetching offers', error);
      }
    };
    fetchOffers();
  }, []);

  const handleSelectOffer = (offer) => {
    setSelectedOffer(offer);
  };

  return (
    <div className={styles.mainContent}>
      <div className={`${styles.cardContainer} ${styles.card1Container}`}>
        <Card1Offers
          onSelectOffer={handleSelectOffer}
          offers={offers}
          trackboxOffers={trackboxOffers}
          setOffers={setOffers}
          setTrackboxOffers={setTrackboxOffers}
        />
      </div>
      <div className={`${styles.cardContainer} ${styles.card2Container}`}>
        <Card2Offers offer={selectedOffer} />
      </div>
    </div>
  );
}

export default MainContentOffers;
