import React, { useEffect, useState, useCallback } from 'react';
import { fetchCRMData, fetchTrackboxCRMData, fetchLeadsData, fetchTrackboxLeadsData } from '../../utils/Trackingapi';
import TopMetrics from '../../CRM/TopMetricsCRM';
import CRMList from './CRMList';
import FTDS from '../../FTDS/FTDS';

const CRMComponent = () => {
  const [leads, setLeads] = useState([]);
  const [dateRange, setDateRange] = useState('today'); // Default to 'today'
  const [leadType, setLeadType] = useState(''); // Use state for lead type
  const [selectedColumns, setSelectedColumns] = useState([
    'First Name', 'Last Name', 'Email', 'Phone', 'IP Address', 'Date & Time', 'Country Name', 'API Name', 'Name','Sale Status', 'Lead' ,'FTDS'
  ]);

  const loadInitialCRMData = useCallback(async () => {
    try {
      const [crmData, trackboxCrmData] = await Promise.all([
        fetchCRMData(dateRange, leadType), 
        fetchTrackboxCRMData(dateRange, leadType)
      ]);
      // Combine CRM data
      const combinedData = [...crmData, ...trackboxCrmData];
      // Initialize leads with an updated flag
      setLeads(combinedData.map(crm => ({ ...crm, lead: 'No' })));
    } catch (error) {
      console.error('Error loading initial CRM data:', error);
    }
  }, [dateRange, leadType]);

  const loadLeadsData = useCallback(async () => {
    try {
      const [leadsData, trackboxLeadsData] = await Promise.all([
        fetchLeadsData(dateRange, leadType), 
        fetchTrackboxLeadsData(dateRange, leadType)
      ]);
      const combinedLeadsData = [...leadsData, ...trackboxLeadsData];

      setLeads(prevLeads =>
        prevLeads.map(lead => {
          const updatedLead = combinedLeadsData.find(ld => ld.clickId === lead.custom5 || ld.clickId === lead.mpc_1);
          return updatedLead ? { ...lead, ...updatedLead, lead: 'Yes' } : lead;
        })
      );
    } catch (error) {
      console.error('Error fetching leads data:', error);
    }
  }, [dateRange, leadType]);

  useEffect(() => {
    loadInitialCRMData();
  }, [loadInitialCRMData]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadLeadsData();
    }, 30000); // Fetch leads data every 30 seconds

    return () => clearInterval(interval);
  }, [loadLeadsData]);

  const handleApplyFilters = (newDateRange, newLeadType, newSelectedColumns) => {
    setDateRange(newDateRange);
    setLeadType(newLeadType);
    setSelectedColumns(newSelectedColumns);
    fetchFilteredCRMData(newDateRange, newLeadType); // Fetch CRM data based on the new filters
  };

  const fetchFilteredCRMData = async (newDateRange, newLeadType) => {
    try {
      const [crmData, trackboxCrmData] = await Promise.all([
        fetchCRMData(newDateRange, newLeadType), 
        fetchTrackboxCRMData(newDateRange, newLeadType)
      ]);
      const combinedData = [...crmData, ...trackboxCrmData];
      setLeads(combinedData.map(crm => ({ ...crm, lead: 'No' })));
    } catch (error) {
      console.error('Error fetching filtered CRM data:', error);
    }
  };

  return (
    <div>
      <TopMetrics
        dateRange={dateRange}
        setDateRange={setDateRange}
        leadType={leadType}
        setLeadType={setLeadType}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        onApplyFilters={handleApplyFilters}
      />
      <h1>CRM Report</h1>
      <CRMList leads={leads} selectedColumns={selectedColumns} />
    </div>
  );
};

export default CRMComponent;
