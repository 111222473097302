import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/auth-service/Login';
import Register from './components/auth-service/Register';
import Dashboard from './components/dashboard/Dashboard';
import PrivateRoute from './components/utils/PrivateRoute';
import Home from './components/Home';
import FTDS from './components/FTDS/FTDS';
import Leads from './components/Leads/Leads';
import CRM from './components/CRM/CRM';
import Networks from './components/Networks/Networks';
import Trackbox from './components/Trackbox/Trackbox';
import Offers from './components/Offers/Offers';
import Schedule from './components/Schedule/Schedule';
import UserAgentSettings from './components/Schedule/UserAgentSettings/UserAgentSettings'; // New import
import ScheduledTasks from './components/Schedule/ScheduledTasks/ScheduledTasks'; // New import
import ProxySettings from './components/Schedule/ProxySettings/ProxySettings'; // New import



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/ftds" element={<PrivateRoute><FTDS /></PrivateRoute>} />
        <Route path="/leads" element={<PrivateRoute><Leads /></PrivateRoute>} />
        <Route path="/crm" element={<PrivateRoute><CRM /></PrivateRoute>} />
        <Route path="/networks" element={<PrivateRoute><Networks /></PrivateRoute>} />
        <Route path="/trackbox" element={<PrivateRoute><Trackbox /></PrivateRoute>} />
        <Route path="/offers" element={<PrivateRoute><Offers /></PrivateRoute>} />
        
        {/* Schedule and its sub-pages */}
        <Route path="/schedule" element={<PrivateRoute><Schedule /></PrivateRoute>} />
        <Route path="/schedule/user-agent-settings" element={<PrivateRoute><UserAgentSettings /></PrivateRoute>} />
        <Route path="/schedule/ProxySettings" element={<PrivateRoute><ProxySettings /></PrivateRoute>} />
        <Route path="/schedule/ScheduledTasks" element={<PrivateRoute><ScheduledTasks /></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
