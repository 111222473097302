import React from 'react';
import './BottomSection.css';

const BottomSection = () => {
  return (
    <div className="bottom-section">
      <div className="footer-content">
        <div className="footer-buttons">
          <button className="footer-button">Home</button>
          <button className="footer-button">Privacy</button>
          <button className="footer-button">Agreement</button>
        </div>
        <div className="footer-text">
          ©2024 Ldxspx, All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default BottomSection;
