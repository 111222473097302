import React from 'react';
import Sidebar from '../../dashboard/Sidebar';
import TopMetrics from './TopMetrics';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import styles from './UserAgentSettings.uas.module.css';

function UserAgentSettings() {
  const [userAgentOption, setUserAgentOption] = React.useState('system');

  const handleUserAgentChange = (e) => {
    setUserAgentOption(e.target.value);
  };

  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="top-metrics">
        <TopMetrics />
      </div>
      <div id="main-content">
        <div className={styles.userAgentSettings}>
          <h2>User-Agent Settings</h2>
          <div className={styles.userAgentSection}>
            <label>User-Agent:</label>
            <div className={styles.twoColumn}>
              <div>
                <label>
                  <input
                    type="radio"
                    value="system"
                    checked={userAgentOption === 'system'}
                    onChange={handleUserAgentChange}
                  />
                  System Generated
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    value="upload"
                    checked={userAgentOption === 'upload'}
                    onChange={handleUserAgentChange}
                  />
                  Upload
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default UserAgentSettings;
