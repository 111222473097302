import React, { useState } from 'react';
import Card1 from './Card1';
import Card2 from './Card2';
import styles from './MainContent.Networks.module.css';

function MainContent() {
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  const handleSelectNetwork = (network) => {
    setSelectedNetwork(network);
  };

  const handleCreateNetwork = () => {
    setSelectedNetwork(null);
  };

  const handleCreateNetworkSuccess = (newNetwork) => {
    setNetworks(prevNetworks => [...prevNetworks, newNetwork]);
    setSelectedNetwork(newNetwork); // Select the newly created network
  };

  const handleUpdateNetworkSuccess = (updatedNetwork) => {
    setNetworks(prevNetworks => prevNetworks.map(network => (network._id === updatedNetwork._id ? updatedNetwork : network)));
    setSelectedNetwork(updatedNetwork); // Select the updated network
  };

  const handleDeleteNetworkSuccess = (deletedNetworkId) => {
    setNetworks(prevNetworks => prevNetworks.filter(network => network._id !== deletedNetworkId));
    setSelectedNetwork(null); // Deselect the deleted network
  };

  return (
    <div className={styles.mainContent}>
      <div className={`${styles.cardContainer} ${styles.card1Container}`}>
        <Card1
          onCreateNetwork={handleCreateNetwork}
          onSelectNetwork={handleSelectNetwork}
          networks={networks}
          setNetworks={setNetworks}
        />
      </div>
      <div className={`${styles.cardContainer} ${styles.card2Container}`}>
        <Card2
          network={selectedNetwork}
          onCreateNetwork={handleCreateNetworkSuccess}
          onUpdateNetwork={handleUpdateNetworkSuccess}
          onDeleteNetwork={handleDeleteNetworkSuccess}
        />
      </div>
    </div>
  );
}

export default MainContent;
