import React, { useState } from 'react';
import axios from '../utils/axiosConfig'; // Import the configured axios instance
import './ForgotPassword.css';

function ForgotPassword({ onClose }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/auth/forgot-password', { email });
      setMessage(response.data.msg);
      setIsError(false);
    } catch (error) {
      setMessage(error.response.data.msg || 'Error sending email');
      setIsError(true);
    }
  };

  return (
    <div className="forgot-password-overlay">
      <div className="forgot-password-popup">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Forgot Password</h2>
        {message && (
          <div className={`message ${isError ? 'error' : 'success'}`}>
            {message}
          </div>
        )}
        <form onSubmit={handleForgotPassword}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit">Send Reset Link</button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
