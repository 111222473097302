import React from 'react';
import Sidebar from '../dashboard/Sidebar';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import CRMComponent from '../Tracking/Leads/LeadsComponent'; // Import CRMComponent
import styles from './Leads.leads.module.css';

function CRM() {
  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="main-content">
        <CRMComponent /> {/* Use CRMComponent here */}
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default CRM;
