import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; // Use the shared Axios instance
import styles from './Card1.Trackbox.module.css';

function Card1({ onCreateTrackbox, onSelectTrackbox, trackboxNetworks, setTrackboxNetworks }) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchTrackboxNetworks = async () => {
      try {
        const response = await axios.get('/api/trackbox/networks'); // Updated endpoint
        setTrackboxNetworks(response.data);
      } catch (error) {
        console.error('Error fetching Trackbox networks', error);
      }
    };
    fetchTrackboxNetworks();
  }, [setTrackboxNetworks]);

  const filteredTrackboxNetworks = trackboxNetworks.filter(network =>
    network.name && network.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={styles.card1}>
      <div className={styles.cardCreate}>
        <button className={styles.createButton} onClick={onCreateTrackbox}>Create New Trackbox Network</button>
      </div>
      <div>
        <input
          type="text"
          placeholder="Search Trackbox"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchBox}
        />
      </div>
      <div className={styles.cardTrackbox} style={{ overflowY: 'scroll', maxHeight: '400px' }}>
        {filteredTrackboxNetworks.length === 0 ? (
          <div>No networks found</div>
        ) : (
          filteredTrackboxNetworks.map(network => (
            <div key={network._id} className={styles.networkItem}>
              <button
                className={styles.networkButton}
                onClick={() => onSelectTrackbox(network)}
              >
                {network.name || 'Unnamed Network'}
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Card1;
