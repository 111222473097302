import React, { useState } from 'react';
import Card1 from './Card1.Trackbox';
import Card2 from './Card2.Trackbox';
import styles from './MainContent.Trackbox.module.css';

function MainContent() {
  const [trackboxNetworks, setTrackboxNetworks] = useState([]);
  const [selectedTrackbox, setSelectedTrackbox] = useState(null);

  const handleSelectTrackbox = (network) => {
    setSelectedTrackbox(network);
  };

  const handleCreateTrackbox = () => {
    setSelectedTrackbox(null);
  };

  const handleCreateTrackboxSuccess = (newTrackbox) => {
    setTrackboxNetworks(prevTrackbox => [...prevTrackbox, newTrackbox]);
    setSelectedTrackbox(newTrackbox); // Select the newly created network
  };

  const handleUpdateTrackboxSuccess = (updatedTrackbox) => {
    setTrackboxNetworks(prevTrackbox => prevTrackbox.map(network => (network._id === updatedTrackbox._id ? updatedTrackbox : network)));
    setSelectedTrackbox(updatedTrackbox); // Select the updated network
  };

  const handleDeleteTrackboxSuccess = (deletedTrackboxId) => {
    setTrackboxNetworks(prevTrackbox => prevTrackbox.filter(network => network._id !== deletedTrackboxId));
    setSelectedTrackbox(null); // Deselect the deleted network
  };

  return (
    <div className={styles.mainContent}>
      <div className={`${styles.cardContainer} ${styles.card1Container}`}>
        <Card1
          onCreateTrackbox={handleCreateTrackbox}
          onSelectTrackbox={handleSelectTrackbox}
          trackboxNetworks={trackboxNetworks}
          setTrackboxNetworks={setTrackboxNetworks}
        />
      </div>
      <div className={`${styles.cardContainer} ${styles.card2Container}`}>
        <Card2
          network={selectedTrackbox}
          onCreateNetwork={handleCreateTrackboxSuccess}
          onUpdateNetwork={handleUpdateTrackboxSuccess}
          onDeleteNetwork={handleDeleteTrackboxSuccess}
        />
      </div>
    </div>
  );
}

export default MainContent;
