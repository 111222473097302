import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable } from 'react-table';
import Modal from 'react-modal';
import styles from './Card2.Schedule.module.css';

Modal.setAppElement('#root'); // Assuming #root is the id of your root element

function Card2({ data }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const columns = useMemo(() => [
    { Header: 'Number', accessor: 'Number' },
    { Header: 'First Name', accessor: 'FirstName' },
    { Header: 'Last Name', accessor: 'LastName' },
    { Header: 'Email', accessor: 'Email' },
    { Header: 'Password', accessor: 'Password' },
    { Header: 'Country Code', accessor: 'CountryCode' },
    { Header: 'Country', accessor: 'Country' },
    { Header: 'Phone Number', accessor: 'PhoneNumber' },
    { Header: 'Status', accessor: 'Status' },
    {
      Header: 'Profile',
      accessor: 'Profile',
      Cell: ({ row }) => (
        <button onClick={() => handleViewProfile(row.original)}>View</button>
      )
    }
  ], []);

  const handleViewProfile = useCallback((profile) => {
    setSelectedProfile(profile);
    setModalIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
    setSelectedProfile(null);
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <div className={styles.card2}>
      <div className={styles.tableContainer}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Profile Modal"
      >
        {selectedProfile && (
          <div>
            <h2>Profile</h2>
            <p><strong>Number:</strong> {selectedProfile.Number}</p>
            <p><strong>First Name:</strong> {selectedProfile.FirstName}</p>
            <p><strong>Last Name:</strong> {selectedProfile.LastName}</p>
            <p><strong>Email:</strong> {selectedProfile.Email}</p>
            <p><strong>Password:</strong> {selectedProfile.Password}</p>
            <p><strong>Country Code:</strong> {selectedProfile.CountryCode}</p>
            <p><strong>Country:</strong> {selectedProfile.Country}</p>
            <p><strong>Phone Number:</strong> {selectedProfile.PhoneNumber}</p>
            <p><strong>Status:</strong> {selectedProfile.Status}</p>
          </div>
        )}
        <button onClick={handleCloseModal}>Close</button>
      </Modal>
    </div>
  );
}

export default Card2;
