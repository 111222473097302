import React from 'react';
import Sidebar from '../dashboard/Sidebar';
import TopMetrics from './TopMetrics';
import MainContent from './MainContent';
import RightSidebar from './RightSidebar';
import BottomSection from './BottomSection';
import styles from './Schedule.Schedule.module.css';

function Schedule() {
  return (
    <div className={styles.dashboardGrid}>
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="top-metrics">
        <TopMetrics />
      </div>
      <div id="main-content">
        <MainContent />
      </div>
      <div id="right-sidebar">
        <RightSidebar />
      </div>
      <div id="bottom-section">
        <BottomSection />
      </div>
    </div>
  );
}

export default Schedule;
