import React from 'react';
import { formatDateTime } from '../../utils/dateTimeUtils';
import styles from './FTDSList.module.css';

const FTDSList = ({ ftds = [], selectedColumns = [] }) => {
  console.log('FTDs:', ftds);
  console.log('Selected Columns:', selectedColumns);

  if (ftds.length === 0) {
    return <div>No FTDs available.</div>;
  }

  if (selectedColumns.length === 0) {
    return <div>Please select columns to display.</div>;
  }

  const columns = {
    'Click ID': ftd => ftd.clickId,
    'Campaign Name': ftd => ftd.ftdData?.campaignName || 'N/A',
    'Country Name': ftd => ftd.ftdData?.countryName || 'N/A',
    'Signup Date': ftd => formatDateTime(ftd.timestamp),
    'Sale Status': ftd => ftd.ftdData?.saleStatus || ftd.ftdData?.customerData?.call_status || 'N/A',
    'Network': ftd => ftd.network || 'N/A',
    'Has FTD': ftd => ftd.ftdData?.hasFTD || 'N/A',
    'Country Code': ftd => ftd.ftdData?.countryCode || 'N/A',
    'Email': ftd => ftd.ftdData?.email || 'N/A',
    'Custom5': ftd => ftd.ftdData?.custom5 || 'N/A',
  };

  return (
    <div className={styles.ftdsList}>
      <table>
        <thead>
          <tr>
            {selectedColumns.map(column => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ftds.map((ftd) => (
            <tr key={ftd._id}>
              {selectedColumns.map(column => (
                <td key={column}>{columns[column] ? columns[column](ftd) : 'N/A'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FTDSList;
