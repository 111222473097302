import React from 'react';

function TemplateSelector({ template, handleTemplateChange, isEditing, trackbox, templates }) {
  return (
    <>
      <label>Choose Template:</label>
      <select
        name="template"
        value={template}
        onChange={(e) => handleTemplateChange(e.target.value)}
        required
        disabled={!isEditing && trackbox && trackbox._id}
      >
        <option value="">Select Template</option>
        {templates.map(template => (
          <option key={template.name} value={template.name}>{template.name}</option>
        ))}
      </select>
    </>
  );
}

export default TemplateSelector;
