// utils/dateTimeUtils.js
export const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid Date';

  return date.toLocaleString(); // Customize the format as needed
};

export const getCurrentDateTime = () => {
  return new Date().toLocaleString(); // Customize the format as needed
};
