import React from 'react';
import styles from './Card2.Trackbox.module.css';

const FormFields = ({ selectedTemplate, formData, handleChange, isEditing }) => {
  if (!selectedTemplate) {
    return null;
  }

  const renderFields = (fields) => {
    return fields.map((field) => (
      <div className={styles.formGroup} key={field}>
        <label>{field}:</label>
        <input
          type="text"
          name={field}
          value={formData[field]}
          onChange={handleChange}
          disabled={!isEditing}
          required={field === 'apiKey' || field === 'offerName' || field === 'offerWebsite' || field === 'domainName' || field === 'EndPointURL' || field === 'APIName'}
        />
      </div>
    ));
  };

  // Split the fields into two columns
  const middleIndex = Math.ceil(selectedTemplate.fields.length / 2);
  const leftColumnFields = selectedTemplate.fields.slice(0, middleIndex);
  const rightColumnFields = selectedTemplate.fields.slice(middleIndex);

  return (
    <div className={styles.formRow}>
      <div className={styles.column}>
        {renderFields(leftColumnFields)}
      </div>
      <div className={styles.column}>
        {renderFields(rightColumnFields)}
      </div>
    </div>
  );
};

export default FormFields;
