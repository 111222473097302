import React, { useState, useEffect } from 'react';
import { getCurrentDateTime } from '../utils/dateTimeUtils'; // Import the utility function
import './TopMetrics.css';

function TopMetrics({ onApplyFilters }) {
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());
  const [dateRange, setDateRange] = useState('today');

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 1000); // Update the time every second

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  const handleDateRangeClick = (range) => {
    setDateRange(range);
  };

  const handleApplyFilters = () => {
    onApplyFilters(dateRange);
  };

  return (
    <div className="top-metrics">
      <div className="header">
        <h2>Dashboard</h2>
        <div className="date-time">{currentDateTime}</div> {/* Display the current date and time */}
      </div>
      <div className="button-card">
        <button
          className={`filter-button ${dateRange === 'today' ? 'active' : ''}`}
          onClick={() => handleDateRangeClick('today')}
        >
          Today
        </button>
        <button
          className={`filter-button ${dateRange === 'yesterday' ? 'active' : ''}`}
          onClick={() => handleDateRangeClick('yesterday')}
        >
          Yesterday
        </button>
        <button
          className={`filter-button ${dateRange === 'thisWeek' ? 'active' : ''}`}
          onClick={() => handleDateRangeClick('thisWeek')}
        >
          This Week
        </button>
        <button
          className={`filter-button ${dateRange === 'lastWeek' ? 'active' : ''}`}
          onClick={() => handleDateRangeClick('lastWeek')}
        >
          Last Week
        </button>
        <button
          className={`filter-button ${dateRange === 'thisMonth' ? 'active' : ''}`}
          onClick={() => handleDateRangeClick('thisMonth')}
        >
          This Month
        </button>
        <button
          className={`filter-button ${dateRange === 'lastMonth' ? 'active' : ''}`}
          onClick={() => handleDateRangeClick('lastMonth')}
        >
          Last Month
        </button>
        <button
          className={`filter-button ${dateRange === 'custom' ? 'active' : ''}`}
          onClick={() => handleDateRangeClick('custom')}
        >
          Custom
        </button>
      </div>
      <div className="apply-button-container">
        <button className="apply-button" onClick={handleApplyFilters}>Apply</button>
      </div>
    </div>
  );
}

export default TopMetrics;
